import offlineManager from "../offlineManager.js";

export default function api(endpoint, method = 'GET', data = null) {
    return fetch(`${process.env.API_URL}/${encodeURI(endpoint)}`, {
        method,
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
        },
        body: data ? JSON.stringify(data) : null,
    })
        .then((r) => {
            if (r.status === 200) {
                offlineManager.setOffline(false);
            }

            return r;
        })
        .then((r) => r.json())
        .catch((e) => {
            console.error(e);
        });
}
